import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Button,
    ConfirmModal,
    Form,
    Panel,
    SaveBar,
    TextField,
    useForm,
    useModal,
} from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import {
    PASSWORD_PLACEHOLDER,
    SmtpFormType,
} from '../../../OperatorSmtpClientModelApi';
import { useOperatorSmtpClientApi } from '../../../operatorSmtpClientApi';
import ConfigurationForm from '../../components/ConfigurationForm';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 100;

const SmtpDetailsEditLoadedPanel = () => {
    const { formatMessage } = useI18n();

    const { showModal } = useModal();

    const {
        createOperatorSmtpValidation: { getConfigurationStepProperties },
        getOperatorSmtpDetails,
        updateOperatorSmtp,
    } = useOperatorSmtpClientApi();

    const { id: operatorAccountId, smtpId } = useRequiredParams<{
        id: string;
        smtpId: string;
    }>();

    const { data: forbiddenDomains } = useSuspenseQuery({
        ...getConfigurationStepProperties(operatorAccountId),
        select: ({ data }) =>
            data.forbiddenDomains?.map((domain) => ({
                regex: new RegExp(`@.*.?${domain}\\.`),
                name: domain,
            })) ?? [],
    });

    const { data } = useSuspenseQuery(
        getOperatorSmtpDetails(operatorAccountId, smtpId)
    );

    const formData = useForm<SmtpFormType>({
        defaultValues: {
            host: data?.host,
            name: data.name,
            password: data.hasPassword ? PASSWORD_PLACEHOLDER : undefined,
            port: data?.port,
            senderEmail: data.senderEmail,
            senderName: data.senderName,
            startTls: data?.startTls,
            type: data.type,
            username: data?.username,
            xvarHeaderPrefix: data?.xvarHeaderPrefix,
        },
    });

    const {
        formState: { isDirty },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = formData;

    const shouldDisableSenderEmail = data.type === 'MIRAKL_SMTP';

    const password = watch('password');
    const isUsernameSet = !!watch('username');
    const isPasswordSet = !!password;
    const isCredentialRequired = isUsernameSet || isPasswordSet;

    const smtpType = watch('type');

    const setPassword = (value: string) => {
        setValue('password', value, {
            shouldDirty: false,
            shouldValidate: true,
        });
    };

    const onSubmit = (values: SmtpFormType) => {
        return updateOperatorSmtp(operatorAccountId, smtpId, values).then(
            () => {
                reset(values);
            }
        );
    };

    return (
        <Form {...formData}>
            <Panel>
                <Panel.Header
                    title={formatMessage({ id: 'operator.smtp.field.type' })}
                />
                <Panel.Content>
                    <TextField
                        label={formatMessage({
                            id: 'operator.smtp.field.name',
                        })}
                        maxLength={NAME_MAX_LENGTH}
                        minLength={NAME_MIN_LENGTH}
                        name="name"
                        required
                    />
                    <ConfigurationForm
                        forbiddenDomains={forbiddenDomains}
                        isCredentialRequired={isCredentialRequired}
                        passwordManagement={{
                            currentValue: password,
                            defaultValue: PASSWORD_PLACEHOLDER,
                            setValue: setPassword,
                        }}
                        senderEmailManagement={{
                            disabled: shouldDisableSenderEmail,
                            tooltipTextId: shouldDisableSenderEmail
                                ? 'operator.smtp.field.sender_email.disabled.tooltip'
                                : undefined,
                        }}
                        smtpType={smtpType}
                    />
                </Panel.Content>
            </Panel>
            {isDirty && (
                <SaveBar
                    actions={[
                        <Button
                            key="saveButton"
                            label={formatMessage({ id: 'button.save' })}
                            submit
                            onClick={handleSubmit(onSubmit)}
                        />,
                        <Button
                            key="cancelButton"
                            label={formatMessage({ id: 'button.cancel' })}
                            variant="secondary"
                            onClick={() =>
                                showModal(
                                    <ConfirmModal
                                        confirmButton={{
                                            label: formatMessage({
                                                id: 'button.discard',
                                            }),
                                            onClick: (close) => {
                                                reset();
                                                close();
                                                return Promise.resolve();
                                            },
                                        }}
                                        content={formatMessage({
                                            id: 'modal.reset.description',
                                        })}
                                        dismissButton={{
                                            label: formatMessage({
                                                id: 'button.discard.cancel',
                                            }),
                                        }}
                                        title={formatMessage({
                                            id: 'modal.reset.title',
                                        })}
                                    />
                                )
                            }
                        />,
                    ]}
                />
            )}
        </Form>
    );
};

const SmtpDetailsEditPanel = () => {
    return (
        <Suspense fallback={<Panel loading loadingVariant="form" />}>
            <SmtpDetailsEditLoadedPanel />
        </Suspense>
    );
};

export default SmtpDetailsEditPanel;
