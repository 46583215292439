import { Suspense } from 'react';

import { useI18n } from '@mirakl/i18n';
import { NavigationButton, PageTitle } from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import OperatorUserGlobalLayout from '../../../common/layout/OperatorUserGlobalLayout';
import OperatorSmtpTitle from '../components/OperatorSmtpTitle';
import { baseSmtpUrlAsOperator } from '../operatorSmtpClientApi';

import SmtpDetailsPanel from './component/SmtpDetailsPanel';

const OperatorSmtpDetailsPage = () => {
    const { formatMessage } = useI18n();

    const { id: operatorAccountId, smtpId } = useRequiredParams<{
        id: string;
        smtpId: string;
    }>();

    return (
        <OperatorUserGlobalLayout>
            <Suspense fallback={<PageTitle loading />}>
                <OperatorSmtpTitle
                    actions={
                        <NavigationButton
                            label={formatMessage({
                                id: 'operator.smtp.title.action.edit',
                            })}
                            to={`${baseSmtpUrlAsOperator(operatorAccountId)}/${smtpId}/edit`}
                            variant="secondary"
                        />
                    }
                />
            </Suspense>
            <SmtpDetailsPanel />
        </OperatorUserGlobalLayout>
    );
};

export default OperatorSmtpDetailsPage;
