import SupportOperatorAccountDetailsLayout from '../../../detail/support/SupportOperatorAccountDetailsLayout';

import SmtpDetailsEditPanel from './component/SmtpDetailsEditPanel';

const SupportOperatorSmtpEditDetailsPage = () => {
    return (
        <SupportOperatorAccountDetailsLayout size="sm">
            <SmtpDetailsEditPanel />
        </SupportOperatorAccountDetailsLayout>
    );
};

export default SupportOperatorSmtpEditDetailsPage;
