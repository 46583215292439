import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { useI18n } from '@mirakl/i18n';
import { Stepper, useNavigate } from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import {
    SmtpFormType,
    createSmtpAsSupportQueryParam,
} from '../../OperatorSmtpClientModelApi';
import {
    baseSmtpUrlAsOperator,
    baseSmtpUrlAsSupport,
    useOperatorSmtpClientApi,
} from '../../operatorSmtpClientApi';

import ConfigurationStep from './components/ConfigurationStep';
import ServerStep from './components/ServerStep';

const OperatorSmtpCreationLoadedPage = () => {
    const { formatMessage } = useI18n();
    const navigate = useNavigate();

    const { id: operatorAccountId } = useRequiredParams<{ id: string }>();

    const { createOperatorSmtp } = useOperatorSmtpClientApi();

    const [searchParams] = useSearchParams();
    const redirectToSupportPage = searchParams.get(
        createSmtpAsSupportQueryParam
    );

    const {
        createOperatorSmtpValidation: { getConfigurationStepProperties },
    } = useOperatorSmtpClientApi();
    const { data: forbiddenDomains } = useSuspenseQuery({
        ...getConfigurationStepProperties(operatorAccountId),
        select: ({ data }) =>
            data.forbiddenDomains?.map((domain) => ({
                regex: new RegExp(`@.*.?${domain}\\.`),
                name: domain,
            })) ?? [],
    });

    const smtpBaseUrl = redirectToSupportPage
        ? baseSmtpUrlAsSupport(operatorAccountId)
        : baseSmtpUrlAsOperator(operatorAccountId);

    const stepperSteps = [
        {
            title: formatMessage({ id: 'operator.smtp.create.server.title' }),
            content: <ServerStep operatorAccountId={operatorAccountId} />,
        },
        {
            title: formatMessage({
                id: 'operator.smtp.create.configuration.title',
            }),
            content: <ConfigurationStep forbiddenDomains={forbiddenDomains} />,
        },
    ];

    return (
        <Stepper<SmtpFormType>
            exitLink={{
                to: smtpBaseUrl,
            }}
            layoutSize="md"
            loading={false}
            stepSaveBarButtonLabels={{ lastButtonLabel: 'Save' }}
            steps={stepperSteps}
            successLink={{
                to: '', // empty string because we will use the navigate in the onGlobalSubmit, waiting ROMA-2371
            }}
            title={formatMessage({ id: 'operator.smtp.configuration' })}
            onGlobalSubmit={(values) => {
                // We want to redirect to a specific page after validation but the Stepper does not have this capability (OnboardingStepper have it but does not correspond to what he want UI/UX wise), it will be added in ROMA-2371
                // This part is inspired from: <https://github.com/mirakl/tool-internal-developer-portal/blob/b46e2835bd592e7873cfbbf4c58069c9c2cdf777/internal-developer-portal-front/src/apps/tenant/pages/TenantCreatePage.tsx#L64>
                let rejectGlobalSubmit:
                    | undefined
                    | ((reason?: unknown) => void);
                const globalSubmitResult = new Promise((_, reject) => {
                    rejectGlobalSubmit = reject;
                });

                // note: do not return this Promise, or else the navigate will be overridden by the successLink of the Stepper
                createOperatorSmtp(operatorAccountId, values)
                    .then((newSmtpId) => {
                        navigate({
                            to: `${smtpBaseUrl}/${newSmtpId}`,
                            shouldNotShowConfirmModal: true,
                        });
                    })
                    .catch((err) => {
                        rejectGlobalSubmit?.(err);
                    });
                return globalSubmitResult;
            }}
        />
    );
};

const OperatorSmtpCreationPage = () => (
    <Suspense fallback={<Stepper loading />}>
        <OperatorSmtpCreationLoadedPage />
    </Suspense>
);

export default OperatorSmtpCreationPage;
