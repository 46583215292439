import { queryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    DatatableSeekPaginateDataType,
    GetQueryParamsValueFromHelperType,
    createQueryParamsHelper,
    useSnackbar,
} from '@mirakl/roma';

import queryClient from '../../config/query/queryClient';
import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';

import {
    PASSWORD_PLACEHOLDER,
    SmtpFormType,
    SmtpStatus,
    SmtpType,
} from './OperatorSmtpClientModelApi';

type OperatorSmtpPayload = {
    id: string;
    name: string;
    senderEmail: string;
    senderName: string;
    status: SmtpStatus;
    type: SmtpType;
};

type ServerConfigurationStepPropertiesPayload = {
    forbiddenDomains?: string[];
};

type OperatorSmtpDetailsPayload = {
    dnsSmtpAuthenticationRecords?: Map<string, string>;
    hasPassword: boolean;
    host?: string;
    id: string;
    name: string;
    port?: number;
    senderEmail: string;
    senderName: string;
    startTls?: boolean;
    status: SmtpStatus;
    type: SmtpType;
    username?: string;
    xvarHeaderPrefix?: string;
};

export const operatorSmtpListQueryParamsHelper = createQueryParamsHelper()
    .withSeekPagination()
    .withSort();

export type OperatorSmtpListingQueryParamsType =
    GetQueryParamsValueFromHelperType<typeof operatorSmtpListQueryParamsHelper>;

export const baseSmtpUrlAsOperator = (operatorAccountId: string) =>
    `/operators/${operatorAccountId}/smtp-configurations`;
export const baseSmtpUrlAsSupport = (operatorAccountId: string) =>
    `/operators/${operatorAccountId}/company/smtp-configurations`;

const privateBaseSmtpUrl = (operatorAccountId: string) =>
    `/private/operators/${operatorAccountId}/smtp-configurations`;

export const useOperatorSmtpClientApi = () => {
    const { formatMessage } = useI18n();
    const { apiGet, apiPost, apiPut } = useAuthenticatedFetch();

    const { addSnackbar } = useSnackbar();

    const getOperatorSmtps: (
        operatorAccountId: string,
        params: OperatorSmtpListingQueryParamsType
    ) => Promise<DatatableSeekPaginateDataType<OperatorSmtpPayload>> =
        useCallback(
            (operatorAccountId, params) =>
                apiGet<DatatableSeekPaginateDataType<OperatorSmtpPayload>>(
                    privateBaseSmtpUrl(operatorAccountId),
                    { params }
                ).then(({ data }) => data),
            [apiGet]
        );

    const getOperatorSmtpDetails = (
        operatorAccountId: string,
        smtpId: string
    ) =>
        queryOptions({
            queryKey: [operatorAccountId, 'smtp', smtpId],
            queryFn: async ({ signal }) => {
                const { data } = await apiGet<OperatorSmtpDetailsPayload>(
                    `${privateBaseSmtpUrl(operatorAccountId)}/${smtpId}`,
                    {
                        signal,
                    }
                );

                if (data.dnsSmtpAuthenticationRecords) {
                    data.dnsSmtpAuthenticationRecords = new Map(
                        Object.entries(data.dnsSmtpAuthenticationRecords)
                    );
                }

                return data;
            },
        });

    const validateServerStep: (
        operatorAccountId: string,
        name: string
    ) => Promise<unknown> = useCallback(
        (operatorAccountId, name) => {
            return apiPost(
                `${privateBaseSmtpUrl(operatorAccountId)}/validation/server`,
                {
                    name: name,
                    operatorAccountId: operatorAccountId,
                }
            );
        },
        [apiPost]
    );

    const getConfigurationStepProperties = (operatorAccountId: string) =>
        queryOptions({
            queryKey: [
                operatorAccountId,
                'smtp',
                'create',
                'configurationStep',
                'properties',
            ],
            queryFn: ({ signal }) =>
                apiGet<ServerConfigurationStepPropertiesPayload>(
                    `${privateBaseSmtpUrl(operatorAccountId)}/validation/configuration`,
                    { signal }
                ),
        });

    const createOperatorSmtp: (
        operatorAccountId: string,
        smtpConfigurationForm: SmtpFormType
    ) => Promise<string> = useCallback(
        (operatorAccountId, smtpConfigurationForm) => {
            const url =
                privateBaseSmtpUrl(operatorAccountId) +
                '/' +
                (smtpConfigurationForm.type === 'SELF_MANAGED'
                    ? 'self-managed'
                    : 'mirakl-smtp');
            return apiPost(url, {
                operatorAccountId,
                ...smtpConfigurationForm,
            })
                .then(({ data }) => {
                    addSnackbar({
                        message: formatMessage({
                            id: 'operator.smtp.create.snackbar.success',
                        }),
                        status: 'success',
                    });
                    return data.smtpId;
                })
                .catch((error: Error | AxiosError) => {
                    addSnackbar({
                        message: formatMessage({ id: 'snackbar.edit.fail' }),
                        status: 'error',
                    });
                    return Promise.reject(error); // Break the promise chain
                });
        },
        [apiPost, addSnackbar, formatMessage]
    );

    const updateOperatorSmtp: (
        operatorAccountId: string,
        smtpId: string,
        smtpConfigurationForm: SmtpFormType
    ) => Promise<void> = useCallback(
        (operatorAccountId, smtpId, smtpConfigurationForm) => {
            const url =
                privateBaseSmtpUrl(operatorAccountId) +
                '/' +
                smtpId +
                '/' +
                (smtpConfigurationForm.type === 'SELF_MANAGED'
                    ? 'self-managed'
                    : 'mirakl-smtp');

            const body = {
                ...smtpConfigurationForm,
                operatorAccountId,
                smtpId,
                updatePassword:
                    smtpConfigurationForm.password !== PASSWORD_PLACEHOLDER,
            };

            return apiPut(url, body).then(() => {
                queryClient.invalidateQueries({
                    queryKey: [operatorAccountId, 'smtp', smtpId],
                });
                addSnackbar({
                    message: formatMessage({
                        id: 'snackbar.edit.success',
                    }),
                    status: 'success',
                });
            });
        },
        [addSnackbar, apiPut, formatMessage]
    );

    return {
        getOperatorSmtps,
        getOperatorSmtpDetails,
        createOperatorSmtpValidation: {
            validateServerStep,
            getConfigurationStepProperties,
        },
        createOperatorSmtp,
        updateOperatorSmtp,
    };
};
