import { Route } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';
import { useI18n } from '@mirakl/i18n';
import { useErrorHandler } from '@mirakl/roma';

import { useFeatureToggle } from '../../common/toggle/FeatureToggleProvider';
import useLoginContext from '../../config/login/LoginProvider';
import useOperatorAccountContext from '../OperatorAccountContext';

import OperatorSmtpDetailsPage from './details/OperatorSmtpDetailsPage';
import OperatorSmtpListingPage from './listing/OperatorSmtpListingPage';
import OperatorSmtpCreationPage from './upsert/create/OperatorSmtpCreationPage';
import OperatorSmtpEditDetailsPage from './upsert/edit/OperatorSmtpEditDetailsPage';

const OperatorSmtpPages = () => {
    const { formatMessage } = useI18n();
    const handleError = useErrorHandler();
    const { isFeatureEnable } = useFeatureToggle();
    const { isAdmin } = useOperatorAccountContext();
    const {
        miraklSupport: { isOperatorSupportUser },
    } = useLoginContext();

    if (!isFeatureEnable('smtp')) {
        handleError(new Error(formatMessage({ id: 'error.404.title' })));
    }

    if (!(isAdmin || isOperatorSupportUser)) {
        handleError(new Error(formatMessage({ id: 'error.403.title' })));
    }

    return (
        <Routes>
            <Route element={<OperatorSmtpListingPage />} index />
            <Route element={<OperatorSmtpCreationPage />} path="/create" />
            <Route element={<OperatorSmtpDetailsPage />} path="/:smtpId" />
            <Route
                element={<OperatorSmtpEditDetailsPage />}
                path="/:smtpId/edit"
            />
        </Routes>
    );
};

export default OperatorSmtpPages;
