import { Suspense } from 'react';

import { LayoutContainer, PageLayout, PageTitle } from '@mirakl/roma';

import OperatorUserGlobalLayout from '../../../../common/layout/OperatorUserGlobalLayout';
import OperatorSmtpTitle from '../../components/OperatorSmtpTitle';

import SmtpDetailsEditPanel from './component/SmtpDetailsEditPanel';

const OperatorSmtpEditDetailsPage = () => {
    return (
        <OperatorUserGlobalLayout>
            <Suspense fallback={<PageTitle loading />}>
                <OperatorSmtpTitle />
            </Suspense>
            <PageLayout size="sm">
                <LayoutContainer>
                    <SmtpDetailsEditPanel />
                </LayoutContainer>
            </PageLayout>
        </OperatorUserGlobalLayout>
    );
};

export default OperatorSmtpEditDetailsPage;
