import { useCallback, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Datatable,
    DatatableHeadingCellType,
    DatatableLoadingType,
    DatatableSeekPaginateDataType,
    DatatableTrailingActionMenuType,
    NavigationButton,
    TextCellContent,
    createHeadingsHelper,
    useNavigate,
} from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import useLoginContext from '../../../../config/login/LoginProvider';
import useOperatorAccountContext from '../../../OperatorAccountContext';
import {
    OperatorSmtpType,
    createSmtpAsSupportQueryParam,
} from '../../OperatorSmtpClientModelApi';
import {
    baseSmtpUrlAsOperator,
    baseSmtpUrlAsSupport,
} from '../../operatorSmtpClientApi';

import OperatorSmtpValidationCellContent from './OperatorSmtpValidationCellContent';

type OperatorSmtpListingDatatableProps = {
    isFromSupportPage?: boolean;
    loadingState: DatatableLoadingType;
    smtps: DatatableSeekPaginateDataType<OperatorSmtpType>;
};

const headingsHelper = createHeadingsHelper<OperatorSmtpType>();

const computeSmtpDetailsUrl = (
    operatorAccountId: string,
    isFromSupportPage: boolean,
    smtpId: string
) =>
    isFromSupportPage
        ? `${baseSmtpUrlAsSupport(operatorAccountId)}/${smtpId}`
        : `${baseSmtpUrlAsOperator(operatorAccountId)}/${smtpId}`;

const computeSmtpEditUrl = (
    operatorAccountId: string,
    isFromSupportPage: boolean,
    smtpId: string
) =>
    isFromSupportPage
        ? `${baseSmtpUrlAsSupport(operatorAccountId)}/${smtpId}/edit`
        : `${baseSmtpUrlAsOperator(operatorAccountId)}/${smtpId}/edit`;

const OperatorSmtpListingDatatable = ({
    isFromSupportPage,
    loadingState,
    smtps,
}: OperatorSmtpListingDatatableProps) => {
    const { formatMessage } = useI18n();

    const navigate = useNavigate();

    const { id: operatorAccountId } = useRequiredParams<{ id: string }>();

    const { roles } = useLoginContext();
    const { isAdmin } = useOperatorAccountContext();
    const canEdit = roles.includes('ROLE_SUPPORT_OPERATOR') || isAdmin;

    const headings: DatatableHeadingCellType[] = useMemo(
        () => [
            headingsHelper.display({
                columnKey: 'name',
                label: formatMessage({
                    id: 'operator.smtp.datatable.column.name',
                }),
                sortable: true,
                initialSortDirection: 'ASC',
                render(rowData) {
                    return (
                        <TextCellContent
                            subtext={formatMessage({
                                id: `operator.smtp.type.${rowData.type}.label`,
                            })}
                            text={rowData.name}
                        />
                    );
                },
            }),
            headingsHelper.display({
                columnKey: 'senderInfo',
                label: formatMessage({
                    id: 'operator.smtp.datatable.column.sender',
                }),
                render(rowData) {
                    return (
                        <TextCellContent
                            subtext={rowData.senderName}
                            text={rowData.senderEmail}
                        />
                    );
                },
            }),
            headingsHelper.accessor('status', {
                label: formatMessage({
                    id: 'operator.smtp.datatable.column.status',
                }),
                render(value) {
                    return <OperatorSmtpValidationCellContent status={value} />;
                },
            }),
        ],
        [formatMessage]
    );

    const trailingActions = useCallback(
        (): DatatableTrailingActionMenuType<OperatorSmtpType> => ({
            a11yActionButtonLabel: formatMessage({
                id: 'operator.smtp.datatable.row_menu.a11y',
            }),
            items: [
                {
                    id: 'view-details',
                    label: formatMessage({
                        id: 'operator.smtp.datatable.row_menu.details',
                    }),
                },
                {
                    id: 'edit',
                    label: formatMessage({
                        id: 'operator.smtp.datatable.row_menu.edit',
                    }),
                },
            ],
            onItemClick: ({ id: smtpId }, item) => {
                switch (item.id) {
                    case 'view-details':
                        navigate({
                            to: computeSmtpDetailsUrl(
                                operatorAccountId,
                                !!isFromSupportPage,
                                smtpId
                            ),
                        });
                        break;
                    case 'edit':
                        navigate({
                            to: computeSmtpEditUrl(
                                operatorAccountId,
                                !!isFromSupportPage,
                                smtpId
                            ),
                        });
                        break;
                }
            },
        }),
        [formatMessage, navigate, isFromSupportPage, operatorAccountId]
    );

    // Base URL is the one of the operator because we are using the exact same page
    let createSmtpUrl = `${baseSmtpUrlAsOperator(operatorAccountId)}/create`;
    if (isFromSupportPage) {
        // We just add this query param to the URL to know where to redirect at the end of the stepper
        createSmtpUrl += `?${createSmtpAsSupportQueryParam}=true`;
    }

    return (
        <Datatable
            data={smtps}
            emptyState={{
                title: formatMessage({
                    id: 'operator.smtp.datatable.empty.title',
                }),
            }}
            headings={headings}
            id="operator-smtp-listing-datatable"
            loading={loadingState}
            renderTrailingActions={trailingActions}
            resultNumberMessage={formatMessage(
                { id: 'count.result' },
                { 0: smtps.count?.counted }
            )}
            toolbar={
                canEdit
                    ? {
                          action: (
                              <NavigationButton
                                  label={formatMessage({
                                      id: 'button.create',
                                  })}
                                  to={createSmtpUrl}
                              />
                          ),
                      }
                    : undefined
            }
            onRowClick={{
                buildUrl: ({ id: rowId }) =>
                    computeSmtpDetailsUrl(
                        operatorAccountId,
                        !!isFromSupportPage,
                        rowId
                    ),
                onClick: ({ url }) => (url ? navigate({ to: url }) : undefined),
            }}
        />
    );
};

export default OperatorSmtpListingDatatable;
