import { FormattedMessage } from 'react-intl';

import { useI18n } from '@mirakl/i18n';
import {
    Hyperlink,
    Panel,
    Paragraph,
    Stepper,
    useStepperForm,
    useStepperValues,
} from '@mirakl/roma';

import { ServerStepFormType } from '../../../OperatorSmtpClientModelApi';
import ConfigurationForm from '../../components/ConfigurationForm';

import Summary from './Summary';

type ConfigurationStepFormType = {
    host: string;
    password: string;
    port?: number;
    senderEmail: string;
    senderName: string;
    startTls: boolean;
    username: string;
    xvarHeaderPrefix: string;
};

type ConfigurationStepProps = {
    forbiddenDomains: {
        name: string;
        regex: RegExp;
    }[];
};

const ConfigurationStep = ({ forbiddenDomains }: ConfigurationStepProps) => {
    const { formatMessage } = useI18n();

    const { type } = useStepperValues<ServerStepFormType>();

    const formData = useStepperForm<ConfigurationStepFormType>({
        defaultValues: {
            senderName: '',
            senderEmail: '',
            xvarHeaderPrefix: '',
            host: '',
            port: undefined,
            username: '',
            password: '',
            startTls: false,
        },
    });

    const { watch } = formData;

    const isUsernameSet = !!watch('username');
    const isPasswordSet = !!watch('password');
    const isCredentialRequired = isUsernameSet || isPasswordSet;

    return (
        <Stepper.FormWithSummary
            {...formData}
            renderSummary={(values) => <Summary {...values} />}
        >
            <Panel>
                <Panel.Header
                    badges={{
                        status: 'info',
                        id: 'badge',
                        label: formatMessage({
                            id: `operator.smtp.type.${type}.label`,
                        }),
                    }}
                    title={formatMessage({
                        id: 'operator.smtp.create.configuration.title',
                    })}
                />

                <Panel.Content>
                    <Paragraph>
                        <FormattedMessage
                            id="operator.smtp.create.configuration.description.SELF_MANAGED"
                            values={{
                                a(chunk) {
                                    // TODO ACCOUNT-1167: add the correct link
                                    return (
                                        <Hyperlink
                                            href="https://example.com"
                                            target="_blank"
                                        >
                                            {chunk}
                                        </Hyperlink>
                                    );
                                },
                            }}
                        />
                    </Paragraph>
                </Panel.Content>
                <Panel.Content>
                    <ConfigurationForm
                        forbiddenDomains={forbiddenDomains}
                        isCredentialRequired={isCredentialRequired}
                        smtpType={type}
                    />
                </Panel.Content>
            </Panel>
        </Stepper.FormWithSummary>
    );
};

export default ConfigurationStep;
