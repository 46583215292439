import { useI18n } from '@mirakl/i18n';
import {
    Checkbox,
    EmailField,
    Flex,
    IntegerNumberField,
    PasswordField,
    TextField,
} from '@mirakl/roma';

import { SmtpType } from '../../OperatorSmtpClientModelApi';

const SENDER_NAME_MAX_LENGTH = 150;
const SENDER_EMAIL_MAX_LENGTH = 150;
const XVAR_HEADER_MAX_LENGTH = 998;
const HOST_MAX_LENGTH = 500;
const VALID_LOCAL_PART_REGEX = /^[a-zA-Z0-9\s"_!#$%&'*+/=?`{|}~^.-]{1,64}$/;
const VALID_DOMAIN_PART_REGEX = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]+$/;

type ConfigurationFormProps = {
    forbiddenDomains: {
        name: string;
        regex: RegExp;
    }[];
    isCredentialRequired: boolean;
    passwordManagement?: {
        currentValue?: string;
        defaultValue: string;
        setValue: (value: string) => void;
    };
    senderEmailManagement?: {
        disabled: boolean;
        tooltipTextId?: string;
    };
    smtpType?: SmtpType;
};

const ConfigurationForm = ({
    forbiddenDomains,
    isCredentialRequired,
    passwordManagement,
    senderEmailManagement,
    smtpType,
}: ConfigurationFormProps) => {
    const { formatMessage } = useI18n();
    return (
        <>
            <TextField
                helpText={formatMessage({
                    id: 'operator.smtp.field.sender_name.description',
                })}
                label={formatMessage({
                    id: 'operator.smtp.field.sender_name',
                })}
                maxLength={SENDER_NAME_MAX_LENGTH}
                name="senderName"
                required
            />
            <EmailField
                disabled={senderEmailManagement?.disabled}
                label={formatMessage({
                    id: 'operator.smtp.field.sender_email',
                })}
                maxLength={SENDER_EMAIL_MAX_LENGTH}
                name="senderEmail"
                required
                tooltipText={
                    senderEmailManagement?.tooltipTextId &&
                    formatMessage({
                        id: senderEmailManagement?.tooltipTextId,
                    })
                }
                validate={(value) => {
                    if (!value) {
                        return true;
                    }

                    const splittedEmail = value.split('@');
                    if (
                        splittedEmail.length !== 2 ||
                        !splittedEmail[0] ||
                        !splittedEmail[1]
                    ) {
                        return true; // Let the EmailField handle the validation error
                    }

                    if (
                        !VALID_LOCAL_PART_REGEX.test(splittedEmail[0]) ||
                        !VALID_DOMAIN_PART_REGEX.test(splittedEmail[1])
                    ) {
                        return formatMessage({
                            id: 'roma.form.email.invalid',
                        });
                    }

                    for (const forbiddenDomain of forbiddenDomains) {
                        if (forbiddenDomain.regex.test(value)) {
                            return formatMessage(
                                {
                                    id: 'operator.smtp.field.sender_email.domain.invalid',
                                },
                                { domain: forbiddenDomain.name }
                            );
                        }
                    }
                }}
            />
            <TextField
                helpText={formatMessage({
                    id: 'operator.smtp.field.xvar_header_prefix.description',
                })}
                label={formatMessage({
                    id: 'operator.smtp.field.xvar_header_prefix',
                })}
                maxLength={XVAR_HEADER_MAX_LENGTH}
                name="xvarHeaderPrefix"
            />

            {smtpType === 'SELF_MANAGED' && (
                <>
                    <Flex flexDirection="row" gap={4}>
                        <TextField
                            label={formatMessage({
                                id: 'operator.smtp.field.host',
                            })}
                            maxLength={HOST_MAX_LENGTH}
                            name="host"
                            required
                        />
                        <IntegerNumberField
                            label={formatMessage({
                                id: 'operator.smtp.field.port',
                            })}
                            max={65535}
                            min={0}
                            name="port"
                            required
                        />
                    </Flex>
                    <TextField
                        label={formatMessage({
                            id: 'operator.smtp.field.username',
                        })}
                        name="username"
                        required={isCredentialRequired}
                    />
                    <PasswordField
                        hidePasswordVisibilityButton={
                            passwordManagement?.currentValue ===
                            passwordManagement?.defaultValue
                        }
                        label={formatMessage({
                            id: 'operator.smtp.field.password',
                        })}
                        name="password"
                        required={isCredentialRequired}
                        onFocus={() => {
                            if (!passwordManagement) {
                                return;
                            }

                            // If the password is the default one, clear it
                            if (
                                passwordManagement.currentValue ===
                                passwordManagement.defaultValue
                            ) {
                                passwordManagement.setValue('');
                            }
                        }}
                    />
                    <Checkbox
                        helpText={formatMessage({
                            id: 'operator.smtp.field.start_tls.description',
                        })}
                        label={formatMessage({
                            id: 'operator.smtp.field.start_tls',
                        })}
                        name="startTls"
                    />
                </>
            )}
        </>
    );
};

export default ConfigurationForm;
